<template>
  <div class="published-person-info-detail app_content">
    <div class="warp_body">
      <div class="publishPersonInfoBody">

        <van-field v-model="worker.name" label="姓名" readonly/>
        <van-field name="radio" label="性别" v-model="worker.gender" readonly>
        </van-field>
        <van-field v-model="worker.phone" label="电话" readonly/>

        <van-field
            v-model="worker.area"
            readonly
            label="区域"
        />

        <van-field v-model="worker.address" rows="1"
                   autosize
                   maxlength="100"
                   type="textarea" label="地址" readonly/>


        <van-field
            v-model="worker.workType"
            readonly
            name="picker"
            label="工种"
        />

        <van-field v-model="worker.workingAge" readonly label="工龄" type="number" maxlength="2"/>

        <van-field
            v-model="worker.introduce"
            rows="3"
            autosize
            readonly
            border="true"
            label="自我介绍"
            type="textarea"
        />
        <van-field
            v-model="worker.projectExperience"
            rows="3"
            autosize
            border="true"
            label="项目经验"
            type="textarea"
            readonly
        />
        <van-field
            v-model="worker.skills"
            rows="3"
            autosize
            readonly
            border="true"
            label="职业技能"
            type="textarea"
        />

        <van-field v-model="worker.attention" center></van-field>
        <div class="contact">
          <van-button @click="getAxb" plain color="black" size="small" style="width: 200px;">联系电话</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, toRaw} from 'vue';
import {onBeforeMount, reactive, toRefs, nextTick, onActivated, onDeactivated} from 'vue'
import {Toast} from 'vant'
import {useRouter} from 'vue-router'
import req from "../request/request";
import API_ORDER from "../api/Order";
import API from "../api/User";
import router from "../router";
import ChooseTime from "@/components/common/chooseTime";
import {useRoute} from "_vue-router@4.0.14@vue-router";
import utils from "@/util/utils";
import API_USER from "@/api/UserReq";

export default {
  name: 'PublishPersonInfo',
  setup() {

    onMounted(() => {

      const route = useRoute();
      let jobId = route.params.workerId
      methodsMap.getJobDetail(jobId)

    });

    const data = reactive({
          worker: {}
        },
    )

    const methodsMap = {

      getAxb: () => {
        let reqJson = {
          "companyUid": JSON.parse(localStorage.getItem("userinfo")).uid,
          "workerUid": data.worker.uid,
        }
        console.log(reqJson)
        API_USER.getSecretNo(reqJson)
      },
      getJobDetail: (workerId) => {
        req.request(API_ORDER.workerDetail + workerId, null, {method: 'GET'}).then((res) => {
          data.worker = res.data.data
          data.worker.attention = '注：用工方对找活者满意，可直接与他联系'
          utils.getArea(data.worker.area).then((d) => {
            data.worker.area = d
          })
        })
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.publishPersonInfoBody {
  .contact{
    position: fixed;
    bottom: 50px;
    right: 20px;
  }
}
</style>
